import { Box } from '@material-ui/core';
import * as React from 'react';
import CustomUiProgress from './CustomUiProgress.web';
import { getStorageData } from '../../../framework/src/Utilities';

export interface IFile {
  "fileName": string,
  "duration": number,
  "progress": number,
  "timeMarkSecond": number,
  "isOptimization": boolean,
  "inputFilePath": string,
  "masterFiles": string
  "folderPath"?: string
  "attachmentId"?: string
}
export default function OptimizationStatus() {
  const [process, setProcess] = React.useState<{ filePath: string, message: string, progress: number }>({ filePath: "", message: "", progress: 0 })
  const [listFile, setListFile] = React.useState<IFile[]>([])
  const [isEnableOptimization, setIsEnableOptimization] = React.useState(false)
  const [listFileProcessing, setListFileProcessing] = React.useState<IFile[]>([]);

  React.useEffect(() => {
    const initializationListFile = async () => {
      const authToken = await getStorageData("authToken");
      if (window?.api) {
        const dataStore = await window?.api?.getListFileProcess();
        if (dataStore.length) {
          setListFile(dataStore)
          for (const data of dataStore) {
            await window?.api?.resumeOptimization(data, authToken, data.folderPath);
          }
        }
      }
    }
    initializationListFile();

    window?.api?.receive('select-video-file', (data: any) => {
      setListFile(data)
    });

    window?.api?.receive('is-enable-optimization', (data: any) => {
      setIsEnableOptimization(data)
    });

    return () => {
      window?.api?.removeAllListeners('select-video-file');
      window?.api?.removeAllListeners('is-enable-optimization');
    };
  }, [])

  React.useEffect(() => {
    window?.api?.receive('video-processing-progress', (data: any) => {
      setProcess(data);
    });
    return () => {
      window?.api?.removeAllListeners('video-processing-progress');
    };
  })

  React.useEffect(() => {
    const mappingListFile = listFile.map(file => {
      if (file.inputFilePath === process.filePath) {
        return { ...file, progress: Math.round(process.progress) }
      }
      return { ...file }
    })
    setListFile(mappingListFile)
    const checkListFileProcessing = async () => {
      try {
        const listOptimizationFile = await window?.api?.getListOptimizationFile();
        setListFileProcessing(listOptimizationFile)
      } catch (error) {
        return []
      }
    }
    checkListFileProcessing();
  }, [process])

  const isCheckHasOptimizedFile = listFile.some(file => file.progress < 100 && !file.isOptimization) || listFileProcessing?.some(file => file.progress <= 100 && !file.isOptimization)
  return (
    <Box>
      {
        isEnableOptimization && <CustomUiProgress isOpenSnackbar= {isCheckHasOptimizedFile} listFile={listFileProcessing?.length ? listFileProcessing : listFile}/>
      }
    </Box>
  );
}

